<template>
  <div class="container">
    <div class="chat-wrap">
      <div class="chat-list">
        <template v-if="chatList && chatList.length>0">
          <div :class="['chat', currentTarget == item.conversation.target?'active':'']" v-for="(item, index) in chatList" :key="index" @click="toMsgDetail(item,index)">
            <LoadImage class="avatar" :src="item.avatar" :default="require('@/assets/images/avatar-default.png')"/>
            <div class="flex-1">
              <div class="flex between-center">
                <div class="name">{{item.name}}</div>
                <div class="time">{{formatMsgDate(item.timestamp)}}</div>
              </div>
              <div class="flex between-center">
                <div class="msg-content" v-if="item.lastMessage">{{getLastMsg(item.lastMessage)}}</div>
                <div class="msg-count" v-if="item.unreadCount.unread != 0">{{item.unreadCount.unread < 99 ? item.unreadCount.unread : '99+'}}</div>
              </div>
            </div>
          </div>
        </template>
        <div class="empty" v-else>
          <Empty tips="暂无消息"/>
        </div>
      </div>
        <!-- 消息详情 -->
      <div class="flex-1">
        <div v-if="$route.path == '/message'" class="empty">
          <Empty :img="require('@/assets/images/nothing-empty.png')" 
          :imgStyle="{width: '208px', height: '170px', 'margin-bottom': '16px'}" tips="暂无消息或未选中聊天"/>
        </div>
        <router-view :key="$route.path"/>
      </div>
    </div>
  </div>
</template>

<script>
import LoadImage from '@/components/LoadImage'
import Empty from '@/components/Empty'
import { connectIM } from '@/utils/jim/im.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
import { formatMsgDate } from '@/utils/util.js'
import EventType from "@/wfc/client/wfcEvent"
export default {
  name: 'Message',
  components: {
    LoadImage,
    Empty
  },
  data() {
    return {
      chatList: [],
      formatMsgDate,
      currentTarget: ''
    }
  },
  created() {
    this.toConnect()
    console.log('路由',this.$route)
  },
  mounted() {
    this.registerEvent()
  },
  beforeDestroy() {
    this.unregisterEvent()
  },
  watch: {
    $route: {
      handler(to, from) {
        if(!to.params.targetId) this.$router.replace('/message')
      },
    immediate: true
    }
  },
  methods: {
    onConnectChange(status) {
      if(status == 1) this.getChatList()
    },
    toConnect() {
      if(this.$im.getConnectionStatus() != 1) {
        connectIM().then(() => {
          this.getChatList()
          // 直接跳转客服
          if(this.$route.params.isToCustomer) this.toCustomer()
        })
      } else {
        this.getChatList()
        // 直接跳转客服
        if(this.$route.params.isToCustomer) this.toCustomer()
      }
    },
    getChatList() {
      console.log('getChatList status', this.$im.getConnectionStatus())
      if(this.$im.getConnectionStatus() != 1) return
      const list = this.$im.getConversationList([0, 1, 2, 3], [0])
      this.chatList = list.filter(item => item.conversation.target !== 'null').map(item => {
        return Object.assign(item, {
          avatar: '',
          name: '',
          entName: '',
          postTitle: ''
        })
      })
      console.log('消息列表', this.chatList)
      this.getAvatar()
    },
    getAvatar() {
      this.chatList.forEach(item => {
        this.$im.getUserInfo(item.conversation.target, res => {
          if(res?.user?.portrait) {
            ossKeyToUrl({ key: res.user.portrait }).then(ossRes => {
              item.avatar = ossRes.url
            }).catch(err => {
              this.$tips({type: 'error', message: err})
            })
          }
          if(res?.user?.display_name) item.name = res.user.display_name || ''
        })
      })
    },
    getLastMsg(lastMsg) {
      if(!lastMsg) return ''
      const msgContent = lastMsg.messageContent || lastMsg.content
      const type = msgContent.type
      if(type == 1 || type == 90) return msgContent.content || msgContent.tip
      const textMap = {
        3: '[图片]',
        4:'[位置]',
        2001:'[位置]',
        2002:'[请求交换联系方式]',
        2003:'[完善联系方式]',
        2004:'[职位卡片]',
        2005:'[报名卡片]',
        2006:'[获取商家联系方式]',
        2007:'[联系方式]',
        1001:'[任务卡片]'
      }
      return textMap[type]
    },
    toMsgDetail({conversation, lastMessage}, index = -1) {
      if(this.currentTarget == conversation.target) return
      this.currentTarget = conversation.target
      // 清除未读数
      if(index >= 0) this.chatList[index].unreadCount.unread = 0
      this.$router.replace({
        name: 'MessageDetail',
        params: {
          randomId: (parseInt(Math.random()*10000) + Date.now()).toString(),
          targetId: conversation.target,
          lastMessageUid: lastMessage?.messageUid || 0,
          conversation: conversation
        }
      })
    },
    toCustomer() {
      const index = this.chatList.findIndex(item => item.conversation.target == 'customer')
      if(index !== -1) {
        this.toMsgDetail(this.chatList[index], index)
      } else {
        let conversation = {
          conversationType: 0,
          type: 0,
          line: 0,
          target: 'customer'
        }
        this.toMsgDetail({conversation})
      }
    },
    registerEvent() {
      this.$im.eventEmitter.on(EventType.ReceiveMessage, this.getChatList)
      this.$im.eventEmitter.on(EventType.SendMessage, this.getChatList)
      this.$im.eventEmitter.on(EventType.UserInfosUpdate, this.getChatList)
      this.$im.eventEmitter.on(EventType.ConnectionStatusChanged, this.onConnectChange)
      this.$im.eventEmitter.on(EventType.SettingUpdate, this.getChatList)
      this.$im.eventEmitter.on(EventType.RecallMessage, this.getChatList)
      this.$im.eventEmitter.on('conversationInfoLoaded', this.getChatList)
      this.$eventBus.$on('UpdateChatList', this.getChatList)
    },
    unregisterEvent() {
      this.$im.eventEmitter.off(EventType.ReceiveMessage, this.getChatList)
      this.$im.eventEmitter.off(EventType.SendMessage, this.getChatList)
      this.$im.eventEmitter.off(EventType.UserInfosUpdate, this.getChatList)
      this.$im.eventEmitter.off(EventType.ConnectionStatusChanged, this.onConnectChange)
      this.$im.eventEmitter.off(EventType.SettingUpdate, this.getChatList)
      this.$im.eventEmitter.off(EventType.RecallMessage, this.getChatList)
      this.$im.eventEmitter.off('conversationInfoLoaded', this.getChatList)
      this.$eventBus.$off('UpdateChatList', this.getChatList)
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 5px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.container {
  position: relative;
  height: calc(100vh - 80px);
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}
.chat-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
}
.chat-list {
  width: 320px;
  height: 100%;
  background-color: #fff;
  overflow-y: scroll;
}
.chat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 72px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  .avatar {
    width: 48px;
    height: 48px;
    border-radius: 24px;
    margin-right: 16px;
    overflow: hidden;
  }
  .name {
    flex: 1;
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 14px;
    font-weight: bold;
  }
  .msg-content {
    font-size: 12px;
    color: #65666F;
    margin-top: 8px;
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .time {
    font-size: 10px;
    color: #B0B3BF;
  }
  .msg-count {
    font-size: 11px;
    width: 32px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    border-radius: 9px;
    background-color: #FF5F58;
    color: #fff;
    margin-top: 8px;
  }
}
.active {
  position: relative;
  background-color: #FFF2ED;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 72px;
    width: 4px;
    background-color: var(--theme-color);
  }
}
.empty {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>